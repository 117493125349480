export default {
    methods: {
        getTimezoneOffset() {
            let date = new Date();
            let timezoneOffset = 0;
            if (date.getTimezoneOffset() < 0) {
                timezoneOffset = (24 + date.getTimezoneOffset() / 60) * 60;
            } else {
                timezoneOffset = Math.abs(date.getTimezoneOffset());
            }
            return timezoneOffset;
        },
    }
}
