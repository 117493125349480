import Vue from "vue";
import VueRouter from "vue-router";
// import request from "../utils/request";
Vue.use(VueRouter);

const routes = [

  {
    path: "/",
    name: "index",
    component: ()=> import("../views/index.vue")
  }
];
const router = new VueRouter({
  routes,
});


export default router;
