export default {
  projectInfo: "projectInfo",
  faultLog: "/faultLog/page",
  liftList: "/lift/page",
  liftDetail: "/lift/realData",
  liftStatistics: "/lift/run/statistics",
  liftStatusStatistics: "/lift/status/statistics",
  eventStatistics: "/eventLog/statistics",
  recentTop:"/faultLog/recent/top",
  weather:"/weather/now",
  eventLogYearPerMonth:"/eventLog/year/perMonth",
  listRealData:"/lift/listRealData",
  faultLogRate:"/faultLog/faultRate"


  // xx:"/api/apps-lift/v1/liftStatusInfo/liftViewList"
};
