import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import dataV from "@jiaminghi/data-view";
// 引入全局css
import "./assets/scss/style.scss";
// 按需引入vue-awesome图标
import Icon from "vue-awesome/components/Icon";
import "vue-awesome/icons/chart-bar.js";
import "vue-awesome/icons/chart-area.js";
import "vue-awesome/icons/chart-pie.js";
import "vue-awesome/icons/chart-line.js";
import "vue-awesome/icons/align-left.js";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import request from "./utils/request";
Vue.prototype.$post = request.post;
Vue.prototype.$get = request.get;
Vue.prototype.$put = request.put;

Vue.prototype.$EventBus = new Vue();

//引入echart
//4.x 引用方式
import echarts from "echarts";
//5.x 引用方式为按需引用
//希望使用5.x版本的话,需要在package.json中更新版本号,并切换引用方式
//import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false;

// 全局注册
Vue.component("icon", Icon);
Vue.use(dataV);
Vue.use(ElementUI);

import mixins from "@/mixins";

Vue.mixin(mixins)

Vue.prototype.$show = function (options) {
  // 创建一个临时的 Vue 实例来承载 Modal
  const modalInstance = new Vue({
    data() {
      return {
        showModal: false,
        title: options.title || '',
        class: options.class || ''
      };
    },
    template: `
      <div>
        <h3>123</h3>
      </div>
    `
  });
  // 将临时实例挂载到一个临时的 DOM 元素上
  const tempDiv = document.createElement('div');
  document.body.appendChild(tempDiv);
  modalInstance.$mount(tempDiv);
  modalInstance.showModal = true;
  return modalInstance;
};



new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
