<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  data() {
    return {
      timer: null,
    };
  },
  components: {},
  mounted() {

  },
  methods: {},
  beforeDestroy() {
  },
};
</script>

<style lang="scss" scoped>
#app {

}
</style>
